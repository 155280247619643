if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

(function(){

    let form_id_3664 = document.querySelector( "input[name='form_id'][value='3664']" );
    if( form_id_3664 ){

        form_id_3664 = form_id_3664.parentElement;

        let tokens = document.createElement( "input" )
        tokens.type = "hidden"
        tokens.name = "_access_token"
        form_id_3664.appendChild( tokens )

        let golden = document.createElement( "input" )
        golden.type = "hidden"
        golden.name = "STAYGOLDEN"
        golden.value = "Enrolled"
        form_id_3664.appendChild( golden )

        let checks = document.querySelectorAll( ".field_3756_21 input" )
        
        checks.forEach( ( item, index ) => {

            item.addEventListener( "click", ( e ) => {

                let token_of_list = "";

                let value_checkbox = e.target.parentNode.querySelector( "input" ).value

                console.log( value_checkbox );

                switch (value_checkbox) {
        
                    case 'special_events_&_happenings':
                        token_of_list = '428d698e-882d-4fe0-8b0d-7e62528ea36e';
                        break;
                    case 'dining_&_culinary':
                        token_of_list = 'd2192d78-0805-476c-a494-00079e96546f';
                        break;
                    case 'spa_viata_&_wellness':
                        token_of_list = '54984e5f-ee5e-40a2-a046-c33a1360c9a4';
                        break;
                    case 'meetings_&_conferences':
                        token_of_list = '77c7e432-017b-4a42-81a3-4f59daa93e10';
                        break;
                    case 'wedding_planning':
                        token_of_list = '4c4e8798-2a44-4fa9-b07a-dac78813e5c3';
                        break;
                    case 'stay_golden_rewards':
                        token_of_list = 'dffac2cf-0c61-40f2-a825-92c102061e7d,cfdffd84-3ed3-4415-970d-652616d2609f'
                }

                let values = tokens.value

                if( values.indexOf( token_of_list ) != "-1" ){
                    values = values.replace( token_of_list + ",", "" )
                    tokens.value = values
                }else{
                    values += token_of_list + ","
                    tokens.value = values
                }

            } )

        } )

    }

    let form_id_3872 = document.querySelector( "input[name='form_id'][value='3872']" );
    if( form_id_3872 ){
        form_id_3872 = form_id_3872.parentElement;

        let golden = document.createElement( "input" )
        golden.type = "hidden"
        golden.name = "STAYGOLDEN"
        golden.value = "Enrolled"
        form_id_3872.appendChild( golden )
    }

    // Funtion to remove elements from a script link.
	if (window.location.hash && window.location.hash.indexOf( "#!" ) != "-1" ) {  
		history.replaceState(null, '', window.location.href.replace('#!', ''));
	}

    /*
        HOME ARROW
        $('.arrow-home').click(function(event) {
            $('body, html').animate({
                scrollTop: $(window).height()-$('header').outerHeight()*2},
                800, 'easeOutExpo');
        });
    */
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
    
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
    
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

    $('body').on('click', '.js-lightbox', function(e) {
        console.log('js-lightbox');
        e.preventDefault();
        var target = $(this).attr('href');
        console.log(target);
        $('.overlay').fadeIn(); 
        $(target).fadeIn(); 
    });
    $('body').on('click', '.popup__close', function() {
        $('.popup').fadeOut ();
        $('.fancybox-bg').fadeOut();
    });
    // if($(".email-sign-up").length > 0) {
    //     $("input[name=email_address]").val(getUrlParameter('email'));	
    // }
    $('#id_1_0_1173').prop('checked', true);

    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
	paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));


    $('.sym-slides').slick({
        accessibility: false,
        pauseOnHover: false
    });
    $('.sym-slides2').slick({
        accessibility: false
    });

    $('.slider__item-text').on('mouseover', function() {
        $(this).closest('.sym-slides').slick('slickPause');
        console.log('slider is paused');
    });

    $('.slider__item-text').on('mouseleave', function() {
        $(this).closest('.sym-slides').slick('slickPlay');
        console.log('slider can resume');
    });

    $('.slider2__item-text').on('mouseover', function() {
        $(this).closest('.sym-slides').slick('slickPause');
        console.log('slider is paused');
    });

    $('.slider2__item-text').on('mouseleave', function() {
        $(this).closest('.sym-slides').slick('slickPlay');
        console.log('slider can resume');
    });

    // ====== UPDATE thank You Message on sign-up form
	// const urlSignUpBTN = 'https://www.hotelviata.com/stay-golden';


	// function createAnchorButton( url ){
    //     let button = document.createElement('a');
    //     button.setAttribute('href', url);
    //     button.setAttribute('class', 'btn btn-primary');
    //     button.textContent = 'Join TODAY';

    //     console.log(button);

    //     return button;
	// }

	// if(document.querySelector('.signup')){
    //     let formMessage = document.querySelector('.signup .thankyouMsg');

    //     let newMessage = "Thank you for signing up.</br> <span>You're Invited!</span> </br>Earn More Every Stay with Stay Golden Rewards at any Meritage Collection property. With this membership, you gain privileged access to exclusive pricing, rewards to enhance your stay, special offers and more. Make your stay count by signing up for our Stay Golden Rewards program and save more. </br>"


    //     formMessage.innerHTML = newMessage;
    //     formMessage.append( createAnchorButton( urlSignUpBTN ) );
	// }
	// END =====  UPDATE thank You Message on sign-up form

// Function to hide elements for Desktop at intro copy.

const introCopy = document.querySelector('.intro__copy');
if( introCopy ){
    const itemToHide = introCopy.querySelector('.only-mobile');
}

if(typeof(itemToHide) != "undefined") {
    const itemContent = itemToHide.textContent;
    hideItem();
}

function hideItem() {
    if (window.innerWidth > 768) {
        itemToHide.parentNode.replaceChild(document.createTextNode(itemContent), itemToHide)        
    } 
}

/*** Function to modify footer sub menu label  ***/


document.addEventListener('DOMContentLoaded', function(){
    const footerSubMenu = document.querySelector('.footer__top nav.sub-menu');
    if( footerSubMenu){
        footerSubMenu.setAttribute('aria-label','Footer Navigation')
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const skipSelectors = [
        '.privary-navigation__item a',
        '.secondary-navigation__item a'
    ];

    skipSelectors.forEach(selector => {
        const elements = document.querySelectorAll(selector);
        if (elements.length > 0) {
            elements.forEach(element => {
                element.setAttribute('tabindex', '-1');
            });
        }
    });
});


//Move btn into quad in meetings and offers
let btnToMove = document.querySelector("#quad-577966 .quad__text-inner .js-lightbox");
let seccionToPut = document.querySelector("#quad-577966 .quad__text-inner .quad-buttons");

if(btnToMove && seccionToPut){
    seccionToPut.insertAdjacentElement('beforeend', btnToMove);
}

let form_signup_lightbox = document.querySelector('#form-sign-up');
    if( form_signup_lightbox ){
        let allGroup = form_signup_lightbox.querySelectorAll('.form-module__group');
        let prev_group = document.querySelector('.js-back_group');
        let next_group = document.querySelector('.js-next_group');
        let div_progress_bar = document.querySelector('.js-progressbar');
        let stateNumberBar = 1;
        let stateProgressBar = 100 / allGroup.length;

        form_signup_lightbox.querySelector('.gdpr-wrap').classList.add('hidden');
        form_signup_lightbox.querySelector('.form-module__submit').classList.add('hidden');
        allGroup.forEach( ( eachGroup, i ) => {
            if( i > 0 ){
                eachGroup.classList.add('hidden');
            }
        } );

        div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;

        prev_group.addEventListener('click', (e) => {
            e.preventDefault();
            let currentGroupActive = form_signup_lightbox.querySelector('.form-module__group:not(.hidden)');
            let prevGroupActive = currentGroupActive.previousElementSibling;
            if( prevGroupActive.classList.contains('form-module__group') ){
                currentGroupActive.classList.add('hidden');
                prevGroupActive.classList.remove('hidden')
                stateNumberBar--;
                stateProgressBar = stateProgressBar - 100 / allGroup.length == 0 ? 25 : stateProgressBar - 100 / allGroup.length;
                div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;
                div_progress_bar.querySelector('progress').value = `${stateProgressBar}`;
                if( stateNumberBar == 1 ){
                    prev_group.classList.add('hidden')
                }

                if( stateNumberBar < allGroup.length ){
                    form_signup_lightbox.querySelector('.gdpr-wrap').classList.add('hidden');
                    form_signup_lightbox.querySelector('.form-module__submit').classList.add('hidden');
                    next_group.classList.remove('hidden');
                }
            }
        });

        next_group.addEventListener('click', (e) => {
            e.preventDefault();
            let currentGroupActive = form_signup_lightbox.querySelector('.form-module__group:not(.hidden)');
            let nextGroupActive = currentGroupActive.nextElementSibling;
            let conunterError = 0;

            currentGroupActive.querySelectorAll('input.required, textarea.required, select.required, checkbox.required').forEach( input => {
                if( input.value == '' || !input.value || input.value == ' ' ){
                    conunterError++;
                }
            } )

            if( conunterError == 0 ){
                if( nextGroupActive.classList.contains('form-module__group') ){
                    currentGroupActive.classList.add('hidden');
                    nextGroupActive.classList.remove('hidden');
                    stateNumberBar++;
                    stateProgressBar = stateProgressBar + 100 / allGroup.length;
                    div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;
                    div_progress_bar.querySelector('progress').value = `${stateProgressBar}`;
                    if ( prev_group.classList.contains('hidden') ) {
                        prev_group.classList.remove('hidden');
                    }
                    if( document.querySelector('.fill_fields') ){
                        document.querySelector('.fill_fields').classList.add('hidden')
                    }
                    if( !nextGroupActive.nextElementSibling.classList.contains('form-module__group') ){
                        form_signup_lightbox.querySelector('.gdpr-wrap').classList.remove('hidden');
                        form_signup_lightbox.querySelector('.form-module__submit').classList.remove('hidden');
                        form_signup_lightbox.querySelector('.advice_to_requiered_fiels').classList.add('hidden');
                        next_group.classList.add('hidden');
                        
                    }
                }
            }else{
                if ( !document.querySelector('.fill_fields') ) {
                    form_signup_lightbox.insertAdjacentHTML( "beforeend", "<strong class='fill_fields'>Please fill all the fields Required *</strong>" );
                }
            }
            
        })
    }


    //Stay navigation link -> avoid redirect
    if( document.querySelector('#privary-navigation a.no-redirect-js') ) {

        let navItem = document.querySelector('#privary-navigation a.no-redirect-js');
        console.log(navItem);
        
        navItem.addEventListener('click', ( e ) => {
            e.preventDefault();
        })

    }

})();