var lastScrollTop = 0;
$(function(){	

	scrollW();
	window.addEventListener('scroll', function(){
		scrollW();
	}); 

	/*MOBILE MENU NAV
	****************************************************************/
	
	//Open Navigation
	$('#menu-btn').click(function(event) { 
		$('html').addClass('hide-overflow');
		$('.navigation').addClass('open');
		$('.navigation').stop().animate({left:'0px'},{duration:300});
		if($('.navigation ul li a').hasClass('.item-open')){
			$(".item-open").get(0).scrollIntoView(false);
		}
	});

	$('.close-menu, .navigation .submenu a').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');
		$('.navigation').removeClass('open');
		$('.navigation').stop().animate({left:'100%'},{queue:false,duration:300});
	}); 

	//SUBNAVIGATION

	// Open active sub-nav on load
	$('.navigation ul.submenu li .active').closest('.inner').addClass('open')
	$('.navigation ul.submenu li .active').parents().parents('li').addClass('item-open');
	$('.navigation ul.submenu li .active').closest('.sub-sub-menu').slideDown(300, function() {}).addClass('menu-open');
 
	//Toggle open/close on tap
	$('.navigation li a').off('click').on('click', function(event) {
		if ( $(this).parent().children(".sub-sub-menu").length > 0 ) {

            event.preventDefault();

           	if ($(this).next().hasClass('menu-open')) {
	           	$(this).closest('.inner').removeClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $(this).next().slideUp(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					} 
                }).removeClass('menu-open');	                
            } else {
	            $(this).closest('.inner').addClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $('.sub-sub-menu.menu-open').slideUp(300).removeClass('menu-open'); 
                $(this).next().addClass('menu-open').slideDown(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					}
                });
            }
        }
    });

	/*CUSTOM SCRIPTS FOR THIS SITE
	****************************************************************/

	
	//Prevents fancybox links from opening
	// $('[data-fancybox]').fancybox({
	// 	buttons: [
	// 		//"zoom",
	// 		//"share",
	// 		//"slideShow",
	// 		//"fullScreen",
	// 		//"download",
	// 		//"thumbs",
	// 		"close"
	// 	],
	// 	wheel:false
	// });
	

	$('[data-fancybox]').each(function(){
		if($(this).attr('href') && $(this).attr('href').indexOf('.pdf') > -1){
			$(this).click(function(){
				let url = $(this).attr('href');
				window.open(url, '_blank');
			});
		} else {
			$(this).fancybox({
				buttons: [
					//"zoom",
					//"share",
					//"slideShow",
					//"fullScreen",
					//"download",
					//"thumbs",
					"close"
				],
				wheel:false
			});
		}
	});
	

	$(".submenu__label, #content .sub-menu li").on("click", function(e){
		$(".sub-menu").slideToggle();
	});

	$('.amenities-icons__list').slick({
		slidesToShow: 2,
		slidesToScroll:2,
		arrows: false,
		dots: true 
	});
	$('.f-specials .container').slick({
		arrows: true, 
		dots: false 
	});
	$('.three-col').slick({
		arrows: true,
		dots: false,
		adaptiveHeight: true,
		accessibility:true
	});

	/*END OF CUSTOM SCRIPTS
	****************************************************************/
	function scrollW(){
		//check scroll down or up for header styles
		var st = window.pageYOffset || document.documentElement.scrollTop;
		
		if(st > 5){
			if (st > lastScrollTop){
				// downscroll code
				if(!document.getElementsByTagName('header')[0].classList.contains('down')){
					document.getElementsByTagName('header')[0].classList.add('down');
					document.getElementsByTagName('header')[0].classList.remove('up');

					document.getElementsByClassName('buttons')[0].classList.add('down');
					document.getElementsByClassName('buttons')[0].classList.remove('up');
				}
			} else {
				// upscroll code
				if(!document.getElementsByTagName('header')[0].classList.contains('up')){
					document.getElementsByTagName('header')[0].classList.add('up');
					document.getElementsByTagName('header')[0].classList.remove('down');

					document.getElementsByClassName('buttons')[0].classList.remove('down');
					document.getElementsByClassName('buttons')[0].classList.add('up');
				}
			}
		} else {
			if(document.getElementsByTagName('header')[0].classList.contains('up') || document.getElementsByTagName('header')[0].classList.contains('down')){
				document.getElementsByTagName('header')[0].classList.remove('up');
				document.getElementsByTagName('header')[0].classList.remove('down');

				document.getElementsByClassName('buttons')[0].classList.remove('down');
				document.getElementsByClassName('buttons')[0].classList.remove('up');
			}
		}
		lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	}

	if (document.querySelector(".restaurant-austin")) {
		if(document.querySelector(".opentable__block")) {
			let openTableContent = document.querySelector(".opentable__block");
			let targetElement = document.querySelector(".intro");
			
			targetElement.parentNode.insertBefore(openTableContent, targetElement);	
		}
	}

	let form_signup_lightbox = document.querySelector('#form-sign-up');
    if( form_signup_lightbox ){
        let allGroup = form_signup_lightbox.querySelectorAll('.form-module__group');
        let prev_group = document.querySelector('.js-back_group');
        let next_group = document.querySelector('.js-next_group');
        let div_progress_bar = document.querySelector('.js-progressbar');
        let stateNumberBar = 1;
        let stateProgressBar = 100 / allGroup.length;

        form_signup_lightbox.querySelector('.gdpr-wrap').classList.add('hidden');
        form_signup_lightbox.querySelector('.form-module__submit').classList.add('hidden');
        allGroup.forEach( ( eachGroup, i ) => {
            if( i > 0 ){
                eachGroup.classList.add('hidden');
            }
        } );

        div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;

        prev_group.addEventListener('click', (e) => {
            e.preventDefault();
            let currentGroupActive = form_signup_lightbox.querySelector('.form-module__group:not(.hidden)');
            let prevGroupActive = currentGroupActive.previousElementSibling;
            if( prevGroupActive.classList.contains('form-module__group') ){
                currentGroupActive.classList.add('hidden');
                prevGroupActive.classList.remove('hidden')
                stateNumberBar--;
                stateProgressBar = stateProgressBar - 100 / allGroup.length == 0 ? 25 : stateProgressBar - 100 / allGroup.length;
                div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;
                div_progress_bar.querySelector('progress').value = `${stateProgressBar}`;
                if( stateNumberBar == 1 ){
                    prev_group.classList.add('hidden')
                }

                if( stateNumberBar < allGroup.length ){
                    form_signup_lightbox.querySelector('.gdpr-wrap').classList.add('hidden');
                    form_signup_lightbox.querySelector('.form-module__submit').classList.add('hidden');
                    next_group.classList.remove('hidden');
                }
            }
        });

        next_group.addEventListener('click', (e) => {
            e.preventDefault();
            let currentGroupActive = form_signup_lightbox.querySelector('.form-module__group:not(.hidden)');
            let nextGroupActive = currentGroupActive.nextElementSibling;
            let conunterError = 0;

            currentGroupActive.querySelectorAll('input.required, textarea.required, select.required, checkbox.required').forEach( input => {
                if( input.value == '' || !input.value || input.value == ' ' ){
                    conunterError++;
                }
            } )

            if( conunterError == 0 ){
                if( nextGroupActive.classList.contains('form-module__group') ){
                    currentGroupActive.classList.add('hidden');
                    nextGroupActive.classList.remove('hidden');
                    stateNumberBar++;
                    stateProgressBar = stateProgressBar + 100 / allGroup.length;
                    div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;
                    div_progress_bar.querySelector('progress').value = `${stateProgressBar}`;
                    if ( prev_group.classList.contains('hidden') ) {
                        prev_group.classList.remove('hidden');
                    }
                    if( document.querySelector('.fill_fields') ){
                        document.querySelector('.fill_fields').classList.add('hidden')
                    }
                    if( !nextGroupActive.nextElementSibling.classList.contains('form-module__group') ){
                        form_signup_lightbox.querySelector('.gdpr-wrap').classList.remove('hidden');
                        form_signup_lightbox.querySelector('.form-module__submit').classList.remove('hidden');
                        form_signup_lightbox.querySelector('.advice_to_requiered_fiels').classList.add('hidden');
                        next_group.classList.add('hidden');
                        
                    }
                }
            }else{
                if ( !document.querySelector('.fill_fields') ) {
                    form_signup_lightbox.insertAdjacentHTML( "beforeend", "<strong class='fill_fields'>Please fill all the fields Required *</strong>" );
                }
            }
            
        })
    }
});
